export enum Role {
  Community = 'Community',
  Agency = 'Agency',
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  User = 'User',
  communityUser = 'Community User',
  ClockIn = 'ClockIn',
  Management = 'Management User',
  ManagementUser = 'Management User'
}
