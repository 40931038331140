export const locale = {
  lang: 'en',
  data: {
    commonSlags: {
      withdraw: "Withdraw",
      withdrawCapital: "Withdraw Capital",
      cntue: "Continue",
      yes: "Yes",
      no: "No",
      cancel: "cancel",
      addAcc: "Add address",
      address: "Give a name to Your wallet.",
      urWlt: "Your wallet",
      noWlt: "No wallet found",
      cancelcAP: "CANCEL",
      saveAdd: "SAVE ADDRESS",
      bitWithdrawalAd: "Bitcoin withdrawal addresses",
      giveWalletName: "Give a name to Your Wallet.",
      wallet: "Wallet",
      desReq: "Description is required",
      bitAdd: "Your Bitcoin withdrawal address.",
      addReq: "Address is required",
      amtReq: "Amount is required",
      amtExceed: "Amount exceeding your balance",
      password: 'Password',
      srch: 'Search',
      wthdrw: 'Withdrawal',
      bckLgn: 'Back to login',
      valExtn: 'Please select a valid extension ',
      minwith: 'is the minimum amount to withdraw',
      enbl2fa: 'Please Enable 2FA and try again',
      amtGrt: 'Amount must be greater than',
      amtexced: 'Amount exceeding your balance',
      unltrnsdtls: 'Withdrawal is in process, please check again later.',
      secCode: 'Security Code',
      add: "Add"
    },

    forgotpassword: {
      title: "Forgot password in your",
      rstPwdTtl: "Reset Password",
      reqOtp: "Email verification code is required",
      emvfCode: "Email verification code",
      sndcd: "Get Code",
      rsnd: "Resend",
      rst: "Reset",
      ent6RcvBy: "Enter the 6-Digit received by",
      entrEml: 'Enter your email and we\'ll send you instructions to reset your password',
      eml: 'Email',
      emlReq: 'Email is required'
    },
    G2FA: {
      verifyUr2Fa: "Verify your 2FA",
      verifyBtn: "Verify",
      enter6dgt: "Enter 6-Digit Code",
      enterValid: "Enter a valid Code",
      plzVrfy: "Please enter a valid 2FA Code",
      entr2fa: "Enter your 2FA Code",
      accessapp: "Access your 2FA application and then enter the 6-Digit code that appears to you for",
      enterOTP: "Please enter email verification code",
      valid2FA: "Enter a valid 2FA Code",
      vrfyitsU: 'To secure your account, please complete the following verification',
      sgnNewdev: 'Confirm New Device Login',
      antphsngCode: 'Anti-Phishing Code',
      chnAnticode: 'Change Anti-Phishing Code',
      chng: 'Change',
      plzFillPhsnCode: 'Anti-Phishing code must be of 4-20 character.',
      whtAntiphs: "What is an Anti-Phishing Code?",
      antiDsc: "An Anti-Phishing Code is a code that helps you prevent phishing attempts from fake websites or emails.",
      hwAnitworks: "How does it work?",
      urCrntCod: "Your current Anti-Phishing Code is",
      oncStpAnti: "Once you've set your unique Anti-Phishing Code. It will be included in all genuine emails from us."
    },
    COMMONERRORS: {
      GETBALANCEERROR: 'Unable to get balance at the moment, Please try again later.',
      SESSIONOUT: 'Your login session is expired, Please login again.',
      FIELDISREUIRED: 'This field is required',
      MUSTMATCHPASS: 'Confirm passowrd must match with password',
      length8: "Must be at least 8 characters!",
      capMust: "Must contain at least 1 in Capital Case!",
      smallMust: "Must contain at least 1 Letter in Small Case!",
      numberMust: "Must contain at least 1 number!",
      specialMust: "Must contain at least 1 Special Character!",
      unableSubmit: "Unable to submit withdrawal request at moment, Please try again later",
      somethingError: "Something went wrong, please try again later.",
      passwordConf: "Confirm Password",
      passwordConfreq: "Confirm Password is required ",
      amountREq: 'Amount is required',
      withReqErr: 'Unable to request withdrawal at the moment, Please try again later.',
    },
    MENU: {
      MYACC: {
        COLLAPSIBLE: 'My Account',
        PROFILE: 'Profile',
        SECURITY: 'Security',
        EMAILCONTACT: 'Email and Contact',
      },
      BALC: {
        COLLAPSIBLE: 'Balance',
        ACTIVITY: 'Activity',
        WITHDRAWAL: 'Withdrawal',
        AVLERN: 'Available Earnings',
        SVNG: 'Savings',
      },
      CAPITAL: {
        COLLAPSIBLE: 'Capital',
        DEPOSIT: 'Deposit',
        MYCAP: 'My Capital',
      },
      BONUS: {
        COLLAPSIBLE: 'Bonus',
        SUMMARY: 'Summary',
        PROFTBONUS: 'Profitability Bonus',
        PROFTEXBONUS: 'Profitability extra bonus',
        AGENTBONUS: 'Agent bonus',
        ZONEAGENTBONUS: 'Zone Agent bonus',
      },
      NEWS: 'News',
      TEAM: 'TEAM',
      INTERNALMSG: 'Internal Messages',
      NOTIFICATION: 'Notifications',
      SUPPORT: 'Support',
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
      },
    },
    DEPOSIT: {
      depoDeposite: 'Deposit',
      depoNow: 'Deposit Now',
      depoHistor: 'Deposit History',
      depoShow: 'Show',
      DEPOSITTERM: 'You can make a deposit to your bloomtrak capital by sending Bitcoins (XBT) that will be converted to EUR and added to your capital when received. First, specify the amount you wish to deposit; next, you will be shown instructions on how to complete the deposit in Bitcoins; please read them and follow them carefully.',
      depoEntry: 'entries',
      depoSearch: 'Search',
      depoChoos: 'Choose A Payment Method',
      depoHour: 'hours',
      depoNote: 'Note',
      depoYoucan: 'You can make a deposit to your bloomtrak capital by sending Bitcoins (XBT) that will be converted to EUR and added to your capital when received. First, specify the amount you wish to deposit; next, you will be shown instructions on how to complete the deposit in Bitcoins; please read them and follow them carefully.',
      depoSelect: 'Select one of the option below or enter the value manually',
      depoSend: 'You will send',
      depoGet: 'You will get',
      depoConti: 'Continue',
      depoExchange: 'Exchange rate will be fixed for 30 minutes',
      depTxn: 'Transaction',
      depTimeLeft: 'Time left to send',
      depGet: 'Get',
      depSend: 'Send',
      depExact: 'Exact Amount',
      depAmnt: 'amount',
      depSendExact: 'Send the exact',
      depSendWithin: 'Send within the limited time',
      depToAdrs: 'to this address',
      depBlockChain: 'Blockchain Fee should be',
      depWithdrawalCh: 'Withdrawal charges a network fee',
      depCompleteAfter: 'Complete after 3 confirmations',
      depTxnHereDetails: 'Here are the details and instructions on how to complete the payment in order to deposit the requested amount to your balance. Please follow these indications carefully. Once the payment has been confirmed, the amount will be credited to your account\'s balance.',
      depoTo: 'To',
      depoIssue: 'Issue while getting address',
      depoMaxDepo: 'Maximum deposit limit reached',
      depoYouOnly: 'you can only deposit for',
      depoMin: 'Minimum of',
      depoMax: 'EURO can be deposited',
      depoAdCopy: 'Address copied',
      depoHistory: 'Deposit History',
      unableToGetDepoHistory: 'Unable to get deposit history, please try again later.',
      minStake: "The minimum amount is",
      stkExcdBal: "Amount exceeding your balance",
    },
    PROFILE: {
      FULLNAME: 'Full name',
      DOBFULL: 'Date of Birth',
      PHONE: 'Phone',
      ADDRESS: 'Address',
      POSTZIP: 'Postal / Zip Code',
      CITYTOWN: 'City or Town',
      COUNTRYLOC: 'Country or Location',
      DOCUMENTNO: 'Identification Document #',
      TWOFAAUTH: 'Two Factor Auth',
      ENTERDOCNUM: 'Enter the number or code of your national identity document (ID card, citizen card, passport, etc). Do NOT write things like "passport" or "ID" in this field, only your document\'s number',
      COMPLIES: 'Revena complies with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of natural persons with regard to the processing of personal data and the free movement of these data and by which Directive 95/46 / CE, General Data Protection Regulation, is repealed.',
      AUTHIMPROVE: 'Improves account and transaction security by adding a second authentication method beyond the login password',
      AUTHSCAN: 'Scan the QR code displayed below, into your favorite authentication app (Authy, Google Authenticator, or similar). This is a unique code created for you, do not share it with anyone else. Keep it well, because it is the only time it will be displayed. When you have successfully configured your app with this code, click Confirm and the second authentication factor will be enabled in your account',
      WARNINGAUTH: 'Warning! When clicking the Confirm button, two-factor authentication will be activated for your account. Please ensure you correctly scanned or copied the code before proceeding, otherwise you could experience errors signing in.',
      ENTER2FA: "Enter Two Factor Code",
      NME: "Name",
      SRNME: "Surname",
      DNI: "DNI",
      DNIDOC: "DNI Document",
      NIE: "NIE or Passport",
      NIEDOC: "NIE or passport Document",
      RMADDRSS: "Room Address",
      TEL: "Telephone",
      EML: "Email",
      Usrnme: "Username",
      pwd: "Password",
      slfWthId: "Selfie with ID Doc.",
      slfVald: "A selfie of you holding a your ID document",
      loginArea: "Login area",
      loginPlatform: "Login platform",
      loginDevice: "Login device",
      loginTime: "Time",
      noRecords: "No Records",
      firstName: 'First Name',
      lastName: 'Last Name',
      driving_licence: 'Driving Liscense',
      username: ' Username',

    },
    news: {
      nws: 'News',
      unableToGetNews: 'Unable to get news, please try again later.'
    },
    internalMessages: {
      intnlMsg: 'Internal Messages',
      msg: 'Messages',
      ntfctn: 'Notifications'
    },
    supportTickets: {
      spprt: 'Support',
      crt: 'Create',
      mytckt: 'My Tickets',
      unbleTopic: 'Unable to load topics at the moment, please try agian later.',
      unbleTickets: 'Unable to load tickets at the moment, please try agian later.',
      unbleTicket: 'Unable to load ticket data at the moment, please try agian later.',
      unbleCrtTickets: 'Unable to create ticket at the moment, please try agian later.',
      unbleSubRpl: 'Unable to reply to the ticket at the moment, please try agian later.',
      msgRplReq: 'Message is requried to reply',
      ifWish: 'If you wish to contact our Support, you can open a new ticket.',
      ticketsTable: {
        sbjct: 'Subject',
        tpc: 'Topic',
        crtd: 'Created',
        sts: 'Status',
        actn: 'Actions',
      },
      crtNwTckt: 'Create a new ticket',
      vwTckt: 'View ticket',
      msg: 'Message',
      viw: 'View',
      rpl: 'Reply',
      cntRplyclsd: 'Can not reply to this ticket'
    },
    login: {
      wlcmTo: 'Welcome to',
      plzSgnAcc: 'Please sign-in to your account and start the adventure',
      emlUsr: 'Email/Username',
      pswd: 'Password',
      rmbrMe: 'Remember Me',
      frgtPwd: 'Forgot Password?',
      sgnIn: 'Sign in',
      pwdReq: 'Password is required',
      emlUsrReq: 'Email/Username is required',
      mustEmail: 'Email must be a valid email address',
      captchaReq: 'Captcha is required',
      usrReq: 'Username is required',
      usrnme: "Username",
      spnrReq: 'Sponsor is required',
      spnr: "Sponsor",
      emlReq: 'Email is required',
      eml: "Email",
      cnfrmpwd: "Confirm Password",
      dscpZne: "Discriminatory Zones",
      alrdyAcct: "Already have an account?",
      sngnInsd: "Sign in instead",
      agrTo: "I agree to",
      trmCnd: "Terms and Conditions",
      rlePrcd: "Rules and procedures",
      knwdTst: "Knowledge Test",
      plzSngAdvtr: "Please sign-up and start the adventure",
      sngUp: "Sign Up",
      accVrfy: "Verify your account",
      affAccVrf: "After account verification you can start using your account.",
      plzwait: "Please wait while we are processing you request",
      cngrts: "CONGRATS!",
      emlvrfd: "YOUR EMAIL IS VERIFIED",
      thnkchc: "THANK YOU FOR YOUR CHOICE",
      yourRefUrl: "Your refferal link",
      copied: "Copied to clipboard",
      rucompany: "Register as a company",
      indvl: "Individual",
      compny: "Company",
      rgstrAs: "Register as",
      compny_name: 'Company Name',
      compny_nmbr: 'Contact Number',
      compny_url: 'Company Url',
      compny_adrss: 'Company Address',
    },
    user: {
      usrList: 'Users List',
      fstName: 'First Name',
      lstName: 'Last Name',
      usrName: 'Username',
      drvName: 'Driver Name',
      cntctNum: 'Contact No.',
      rgstd: 'Registered On',
      usrEml: 'Email',
      sts: 'Status',
      drvrList: 'Drivers List'
    },
    Notification: {
      ntnfcs: "Notifications",
      dt: "Date",
      frusr: "For User",
      ttle: "Title",
      ntfcn: "Notification",
      atcn: "Actions"
    },
    coupons: {
      nme:"Name",
      cpn: "Coupon Code",
      cd: "code",
      tpe: "Type",
      dscptn: "Description",
      dscnt: "Discount",
      prcnt: "Percentage",
      usr:"Users",
      stdate:"Start Date",
      endate:"End Date",
      img:"Image",
      uscnt: "Usage Count",
      ttle: "Title",
      sts: "Status",
    },
    vehicles: {
      dt: "Registed Date",
      vhcls: 'Vehicles',
      advhcl: 'Add Vehicle',
      nme: "Name",
      rgtrNo: "Registration No.",
      dsc: "Description",
      engTyp: "Engine Type",
      sts: "Seats",
      vchlType: "Vehicle Type",
      arbg: "Air Bag",
      crtnLoc: "Current Location",
      crtnCt: "Current City",
      cat: "Category",
      stus: "Status",
      kp:"KPG",
      gpnav:"GPS Navigation",
      bluaud:"Bluetooth Audio",
      bacupcam:"Back up Camera",
      drrec:"Drive Recorder",
    },

    faq:{
      faqList:"FAQ List",
      faqSearch: 'Search',
      ttle: "Title",
      dscptn: "Description",
      atcn: "Actions",
      lan:'Language',
    }
  }
};
