<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <!-- <img src="{{ coreConfig.app.appLogoImage}}" alt="brand-logo" height="36"/> -->
          <img src="{{ coreConfig.layout.skin == 'dark' ? 'assets/images/logo/admin-logo.png' : 'assets/images/logo/admin-logo.png' }}" alt="brand-logo" height="36" />
        </span>
        <img src="{{ coreConfig.layout.skin == 'dark' ? 'assets/images/logo/adminBloom.png' : 'assets/images/logo/adminBloom.png' }}" class="adminText"/>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName | lowercase }}</h2> -->
      </a>
    </li>
    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible('d')">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" *ngIf="currentUser" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main mt-2" layout="vertical" core-menu></ul>
  <div class="text-center my-3" *ngIf="accTo?.access_to && accTo?.access_to != 'spend_trak' && currentUser?.user_role !='4'&& currentUser?.user_role !='1' || vwPrms == '1' || currentUser?.user_role=='1'&&(accTo?.access_to =='shifttrak' || accTo?.access_to =='both')">
   <a [download]="myFileName" [href]="fileUrl"><h4 style="color: #ff6633 ; font-weight: bold; text-decoration: underline ; ">Download Agency Shift Variance Form</h4></a>
</div>
<!--/ Main menu -->

<button   class="btn-sm btn-info btn-block" (click)="goToMail()">Email Support</button>