<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown" (click)="checkNotification()"
    ><i class="ficon" data-feather="bell"></i
    ><span *ngIf="notocountlength > 0" class="badge badge-pill badge-danger badge-up">{{
      NotificationCount
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ NotificationCount }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications">
        <div class="media d-flex align-items-start" [class.new_noty]="message.is_deleted == '0'">
          <div class="media-left">
            <div>
              <i class="ficon" data-feather="bell"></i>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message?.title"></p>
            <small class="notification-text">{{ message?.body }}</small>
          </div>
        </div>
      </a>
      <button *ngIf="notocount > 5" ngbDropdownItem (click)="toggleShowAll()" class="badge badge-pill badge-light-primary mt-1">See More</button>
    </li>    
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <!-- <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>
    </li> -->
    <!--/ Notifications footer -->
  </ul>
</li>
